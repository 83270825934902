import { get, isEmpty } from "lodash";
import { removeTypename } from "js/utils/Helper";
import client from "js/App/client.graphql.js";
import AppQraphQL from "js/graphql/resolvers/idioma.resolver";
import UsuarioGraphQL from "js/graphql/resolvers/usuario.resolver";

const IDIOMA = "idioma";
export const LOCAL = "local";
export default class TranslationService {
  static initIdioma = (idiomaDefault, miIdioma) => {
    if (!isEmpty(miIdioma)) return TranslationService.set(miIdioma);
    // if (!TranslationService.getIdiomaId())
    return TranslationService.set(idiomaDefault);
    // client
    //   .query({
    //     query: IdiomaGraphQL.queries.getIdiomaById,
    //     fetchPolicy: "network-only",
    //     errorPolicy: "all",
    //     variables: { id: TranslationService.getIdiomaId() }
    //   })
    //   .then(res => {
    //     const idioma = get(res, "data.getIdiomaMerged");
    //     if (idioma) TranslationService.setIdioma(res.data.getIdiomaMerged);
    //   });
  };

  static setIdioma = async idioma => {
    if (isEmpty(idioma)) return;
    TranslationService.set(idioma);
    await client.mutate({
      mutation: UsuarioGraphQL.mutations.updateMiUsuario,
      errorPolicy: "all",
      fetchPolicy: "no-cache",
      variables: { usuario: { idIdioma: idioma._id } }
    });
  };

  static set(idioma) {
    window.localStorage.setItem(IDIOMA, JSON.stringify(removeTypename(idioma)));
  }

  static getIdiomaId() {
    const idioma = window.localStorage.getItem(IDIOMA);
    const idiomaObj = JSON.parse(idioma);
    return get(idiomaObj, "_id", null);
  }

  static getIdioma() {
    const idioma = window.localStorage.getItem(IDIOMA);
    return JSON.parse(idioma) || {};
  }

  static getIdiomaTraducciones() {
    const idioma = window.localStorage.getItem(IDIOMA);
    const idiomaObj = JSON.parse(idioma);
    return get(idiomaObj, "traducciones", null);
  }

  static addKey(key, local) {
    client.mutate({
      mutation:
        local === LOCAL
          ? AppQraphQL.mutations.addKeyToPrograma
          : AppQraphQL.mutations.addKeyToPadres,
      errorPolicy: "all",
      fetchPolicy: "no-cache",
      variables: {
        key
      }
    });
  }
}

export const _t = (text, local = false) => {
  if (typeof text !== "string") return text;
  if (isEmpty(text)) return true;
  const traducciones = TranslationService.getIdiomaTraducciones();

  // Normalizamos el texto eliminando números entre paréntesis y espacios opcionales
  const normalizarTexto = txt => {
    return txt.replace(/\(\d+\)\s*/g, "").trim();
  };

  const textoNormalizado = normalizarTexto(text);

  const traduccion =
    traducciones &&
    traducciones.find(i => {
      // Normalizamos también la clave almacenada para comparar
      const claveNormalizada = normalizarTexto(i.key);
      return claveNormalizada === textoNormalizado;
    });

  // !traduccion && TranslationService.addKey(text, local);
  return (traduccion && traduccion.value) || text;
};
